<template>
  <div id="body" class="login_cliente">
    <div class="contenido">
      <v-col cols="12" sm="8" md="6">
        <v-card class="card">
          <v-toolbar class="header" color="black" dark flat>
            <v-toolbar-title>Iniciar sesión</v-toolbar-title>
          </v-toolbar>
          <div class="v-card-text px-4 mt-4">
            <form>
              <v-text-field v-model="cedula" type="number">
                <template v-slot:label>
                  <div class="tem-label">
                    Cédula
                  </div>
                </template>
              </v-text-field>
            </form>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link :to="`/registerClientes/${$route.params.data}`">
              <v-btn text>Registrar</v-btn>
            </router-link>
            <v-btn color="primary" @click="login()">Acceder</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </div>
    <v-snackbar v-model="snackbar" top right color="error">
      {{ alert }}
      <v-btn
        text
        @click="
          snackbar = false;
          alert = '';
        "
        >X</v-btn
      >
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "loginCliente",
  data: () => ({
    cedula: "",
    snackbar: false,
    alert: "",
  }),
  methods: {
    login() {
      if (this.cedula != "") {
        axios({
          method: "POST",
          url: "/login_cliente_web",
          data: JSON.stringify({
            cedula: this.cedula,
            token: this.$route.params.data,
          }),
          responseType: "json",
        })
          .then((response) => {
            if (response.data.success) {
              this.$router.push("/agendarCliente/" + response.data.data + '/'+this.$route.params.data);
            }
          })
          .catch((error) => {
            this.alert = error.response.data.data;
            this.snackbar = true;
          });
      }
    },
  },
};
</script>
<style scoped>
.contenido {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("https://i.imgur.com/4AiSeuI.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5) !important;
  background: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.header {
  background: rgba(0, 0, 0, 0.5) !important;
}
.tem-label {
  color: rgb(0, 0, 0);
}
</style>
